export default [
  {
    path: '/contracts/closed',
    name: 'contracts_closed',
    component: () => import('@/views/pages/contracts/Closed'),
    meta: {
      pageTitle: 'Closed Contracts',
      resource: 'contracts_closed',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/tia',
    name: 'contracts_tia',
    component: () => import('@/views/pages/contracts/TIA'),
    meta: {
      pageTitle: 'TIA Contracts',
      resource: 'contracts_tia',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/active',
    name: 'contracts_active',
    component: () => import('@/views/pages/contracts/Active'),
    meta: {
      pageTitle: 'Active Contracts',
      resource: 'contracts_active',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/lapsed',
    name: 'contracts_lapsed',
    component: () => import('@/views/pages/contracts/Lapsed'),
    meta: {
      pageTitle: 'Lapsed Contracts',
      resource: 'contracts_lapsed',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/need-renewal',
    name: 'contracts_renew',
    component: () => import('@/views/pages/contracts/NeedRenewal'),
    meta: {
      pageTitle: 'Need Renewal Contracts',
      resource: 'contracts_renew',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/:contract_id/edit',
    name: 'edit_contract',
    component: () => import('@/views/pages/contracts/EditContract'),
    meta: {
      pageTitle: 'Edit Contract',
      resource: 'edit_contract',
      action: 'read',
    },
  },
]
